// Login
export const LOGIN = '/admin/admin_login'

// Update Customer
export const UPDATE_CUSTOMER = '/dashboard/admin_update_user_data'

// Forget password
export const FORGET_PASSWORD = '/admin/forgot_password'

// Reset Password
export const RESET_PASSWORD = '/admin/reset_password'

// Add member
export const ADD_MEMBER = '/setting/add_member'

// Delete member
export const DELETE_MEMBER = '/setting/delete_member'

// Fetch members
export const FETCH_MEMBERS = '/setting/get_member'

// Upload Image
export const UPLOAD_IMAGE = '/upload/upload_document'

// Update Member
export const UPDATE_MEMBER = '/setting/update_member'
