import React, { useState } from 'react';

const useTab = (defaultTab = 1) => {
    const [tabActive, setTabActive] = useState(defaultTab);
    const onChangeTab = (key) => {
        console.log(key, ' key.')
        setTabActive(key);
    };
    const handleSectionHideAndShow = () => {
        setTabActive(false);
    };

    return [tabActive, onChangeTab, handleSectionHideAndShow];
}

export default useTab;