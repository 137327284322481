import { useState } from 'react'

const useSearch = data => {
  const [filteredData, setFilteredData] = useState([])
  const [isSearching, setIsSearching] = useState([])
  /*
   * Find Is Value exist in Object
   * @param {object} - obj
   * @param {string} - value
   * @return {boolean}
   */
  const findValueInObj = (obj, value) => {
    const { name, email, phone, first_name, last_name, role } = obj
    return (
      (name && name.toUpperCase().match(value.toUpperCase())) ||
      (email && email.toUpperCase().match(value.toUpperCase())) ||
      (phone && phone.match(value.toUpperCase())) ||
      (first_name && first_name.toUpperCase().match(value.toUpperCase())) ||
      (last_name && last_name.toUpperCase().match(value.toUpperCase())) ||
      (role && role.match(value.toUpperCase()))
    )
  }

  /** Search Value Inside provided Object
   * @param {String} Value
   * @param {Array} arr
   */
  const onSearch = (value, arr) => {
    const filteredData = arr?.filter(row => {
      return findValueInObj(row, value)
    })
    ;(value && setIsSearching(true)) || setIsSearching(false)
    setFilteredData([...filteredData])
  }

  return [isSearching, filteredData, onSearch]
}

export default useSearch
