import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronDown, FaEnvelope } from "react-icons/fa";
import { AiFillDelete, AiFillPhone, AiOutlineSearch } from "react-icons/ai";

import { Table, Avatar, Button, Modal, Input, Form, Spin } from "antd";

// Hooks
import useSearch from "../../hooks/useSearch";
import useApi from "../../hooks/useApi";

// context API
import { CffFormContext } from "../../contexts/cffDataContext";
import * as PropTypes from "prop-types";
import TeamManagement from "./team-managment/TeamManagement";

// utils / helper
import {
  generateAvatarColor,
  displayFirstChar,
  Salert,
  notify,
} from "../../utils/helpers";

function Panel(props) {
  return null;
}

Panel.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
};
const Settings = () => {
  // >>> Hooks and States <<<
  const [member, setMember] = useState({
    isEdit: false,
    memberId: "",
  });
  const [backgroundColor, setBackgroundColor] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [model, setModel] = useState(false);
  const { addMember, fetchMembers, deleteMember, updateMember } = useApi();
  const [userInfo, setUserInfo] = useState({});
  const [customersData, setcustomersData] = useState([]);
  const [isSearching, filteredData, onSearch] = useSearch();
  const { setCffFormData, selectCurrentFormData, currentFromData } =
    useContext(CffFormContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  /** Create new member */
  const handleRegistar = () => {
    const payload = {
      ...userInfo,
    };

    // API
    if (member.isEdit) {
      updateMember({ ...payload }, member.memberId).then(() => {
        listMembers();
        notify("Member Updated");
        setModel(false);
      });
    } else {
      addMember({ ...payload }).then(() => {
        listMembers();
        notify("Member Added");
        setModel(false);
      });
    }
  };

  /** Its best example of Lexicle Scoping... */
  // Handle Delete
  const handleDelete = (id) => {
    // High order
    Salert("Delete it.!", "delete", () => deleteMemberFromList(id));
  };

  const handleEdit = (email, id) => {
    setMember((prev) => ({ ...prev, isEdit: true, memberId: id }));
    selectCurrentFormData(email);
    setModel(true);
    // Navigator to next route
    // navigate(`/${email}`);
  };

  const handleAddNewMember = () => {
    setMember((prev) => ({ ...prev, isEdit: false }));
    setModel(true);
    setUserInfo({});
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Callback
  const deleteMemberFromList = (id) => {
    deleteMember(id).then((res) => {
      listMembers();
    });
  };

  const listMembers = () => {
    setIsLoading(true);
    fetchMembers().then((res) => {
      setcustomersData(res.data?.member_data);
      setCffFormData(res.data?.member_data);
      setData(res.data?.member_data);
      setIsLoading(false);
    });
  };

  //  >>> Use Effects <<<
  useEffect(() => {
    if (Object.keys(currentFromData).length) {
      setUserInfo(currentFromData);
    }
  }, [currentFromData]);

  useEffect(() => {
    listMembers();
  }, []);

  useEffect(() => {
    console.log(member, " member...");
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend", "ascend"],
      render: (first_name, { last_name }) => {
        return (
          <>
            <Avatar
              style={{
                backgroundColor: `#${generateAvatarColor()}`,
                verticalAlign: "middle",
                textTransform: "capitalize",
                marginRight: "5px",
              }}
              size={40}
              gap={5}
            >
              {displayFirstChar(first_name)}
            </Avatar>{" "}
            {first_name + " " + last_name}
          </>
        );
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
      render: (email) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FaEnvelope
              style={{ height: 25, fontSize: 16, color: "#0360CC" }}
            />
            <p>{email}</p>
          </div>
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone - b.phone,
      render: (phone) => {
        return (
          <>
            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
              <AiFillPhone
                style={{ color: "#0360CC", fontSize: 16, height: 25 }}
              />
              <p>{phone}</p>
            </div>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      render: ({ email, id }) => {
        return (
          <div style={{ display: "flex", columnGap: 20 }}>
            <Button
              onClick={() => handleEdit(email, id)}
              disabled={handleRoleValidation()}
              to="#"
              style={{
                backgroundColor: `${
                  (handleRoleValidation() && "#555") || "var(--Red)"
                }`,
                color: "var(--White)",
                padding: "0.3rem 1rem",
                borderRadius: "0.375rem",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                columnGap: 10,
              }}
              icon={<FaChevronDown />}
            >
              Edit
            </Button>
            <Button
              onClick={() => handleDelete(id)}
              disabled={handleRoleValidation()}
              to="#"
              style={{
                backgroundColor: `${
                  (handleRoleValidation() && "#555") || "var(--Red)"
                }`,
                color: "var(--White)",
              }}
              icon={<AiFillDelete />}
            />
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            return index % 2 === 0;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            return index % 2 !== 0;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  /** Role validation */
  const handleRoleValidation = () => {
    const role = localStorage.getItem("role");
    if (role === "admin") {
      return false;
    } else if (role === "user") {
      return true;
    }
  };

  const handleSearch = ({ target: { value } }) => {
    onSearch(value, data);
    console.log(filteredData, " filtred", data);
  };

  // Debuggin area ->>>>>>>
  useEffect(() => {
    console.log(userInfo, " filteredData");
  });

  return (
    <>
      <div className="customer">
        {/*  Add Customer Model */}
        <Modal
          title={<h4 style={{ fontWeight: 700 }}>Team Management</h4>}
          centered
          open={model}
          onOk={() => handleRegistar()}
          onFinish={() => alert("here")}
          onCancel={() => setModel(false)}
          width="680px"
          footer={[
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="primary"
                danger
                id="password"
                style={{
                  background: "#FFFFFF",
                  color: "#000",
                  borderRadius: 5,
                  borderColor: "#A5A5A5",
                  padding: 20,
                }}
              >
                <p style={{ marginTop: -10 }}>Cancel</p>
              </Button>

              <Button
                type="primary"
                danger
                id="password"
                style={{
                  background: "#A20010",
                  borderRadius: 5,
                  padding: 20,
                }}
              >
                <p style={{ marginTop: -10 }} onClick={handleRegistar}>
                  {(member.isEdit && "Update User") || "Create User"}
                </p>
              </Button>
            </div>,
          ]}
        >
          <div>
            <Form>
              <TeamManagement userInfo={userInfo} setUserInfo={setUserInfo} />
            </Form>
          </div>
        </Modal>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h4 style={{ marginBottom: 10, fontWeight: 600 }}>Settings</h4>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h6 style={{ color: "#A20010", fontWeight: 700 }}>
                Team Management
              </h6>
              <div
                style={{ height: 3, width: "100%", background: "#A20010" }}
              ></div>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
            <Button
              style={{
                position: "relative",
                color: "#fff",
                backgroundColor: `${
                  (handleRoleValidation() && "#555") || "var(--Red)"
                }`,
              }}
              className="addBtn"
              onClick={handleAddNewMember}
              disabled={handleRoleValidation()}
            >
              Add new Member
            </Button>
            <Input
              placeholder="Search by name, email or phone"
              prefix={<AiOutlineSearch />}
              style={{ borderRadius: 5 }}
              onChange={handleSearch}
            />
          </div>
        </div>
        {(isLoading && (
          <Spin size="large" style={{ marginTop: "25%", marginLeft: "50%" }} />
        )) || (
          <Table
            columns={columns}
            dataSource={(!isSearching && filteredData) || data}
          />
        )}
      </div>
    </>
  );
};

export default Settings;
