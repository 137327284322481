import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa";

// Context
import { LoginContext } from "../contexts/loginContext";

// Company Logo
import logo from "../assests/logo.svg";
import { Salert } from "./../utils/helpers";

const Sidebar = () => {
  const { setIsLogin } = useContext(LoginContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeClass, setActiveClass] = useState("");

  const checkIsEmail = (str) => {
    const atSymbole = str.indexOf("@");
    const dotCom = str.indexOf(".com");

    return atSymbole && dotCom;
  };

  useEffect(() => {
    const activePath = location.pathname.split("/")[1];
    if (checkIsEmail(activePath) > 0) {
      setActiveClass("dashboard");
    } else {
      setActiveClass(activePath);
    }
  }, [location.pathname]);

  return (
    <div className="sidebar">
      <div className="logo">
        <img src={logo} alt="CFF logo" />
      </div>

      <div className="sidebar-items">
        {/* Dashboard */}
        <Link
          to="/dashboard"
          onClick={() => {
            setActiveClass("dashboard");
          }}
        >
          <div
            className={`item ${
              (activeClass === "dashboard" && "active") || ""
            }`}
          >
            <p>Dashboard</p>
          </div>
        </Link>

        {/* Customers */}
        <Link
          to="/customer"
          onClick={() => {
            setActiveClass("customer");
          }}
        >
          <div
            className={`item ${(activeClass === "customer" && "active") || ""}`}
          >
            <p>Customers</p>
          </div>
        </Link>

        {/* Quoting */}
        <Link
          to="/quoting"
          onClick={() => {
            setActiveClass("quoting");
          }}
        >
          <div
            className={`item ${(activeClass === "quoting" && "active") || ""}`}
          >
            <p>Quoting</p>
          </div>
        </Link>

        {/* Settings */}
        <Link
          to="/settings"
          onClick={() => {
            setActiveClass("settings");
          }}
        >
          <div
            className={`item ${(activeClass === "settings" && "active") || ""}`}
          >
            <p>Settings</p>
          </div>
        </Link>

        {/* Logout */}
        <Link
          style={{
            position: "absolute",
            bottom: 0,
            left: 30,
            padding: 0,
            margin: 0,
          }}
          to="/login"
          onClick={() => {
            Salert("Logout.!", "logout", () => {
              setIsLogin(false);
              localStorage.removeItem("user");
              navigate("/login");
            });
          }}
        >
          <div
            className={`item ${(activeClass === "logout" && "active") || ""}`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FaPowerOff />
              <p style={{ marginLeft: 10 }}>Logout</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
