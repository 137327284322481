import React, { useState } from 'react'
import axios from 'axios'
import {
  LOGIN,
  UPDATE_CUSTOMER,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  ADD_MEMBER,
  FETCH_MEMBERS,
  UPLOAD_IMAGE,
  DELETE_MEMBER,
  UPDATE_MEMBER
} from './url_helper'
import { PATCH, POST, GET, DELETE, PUT } from './api_methods'

const BASE_URL = 'https://backend.cffinsure.com/v1/api'

const generateUrl = (name, id) => {
  switch (name) {
    case 'customer':
      return `${BASE_URL}${UPDATE_CUSTOMER}/${id}`
    case 'login':
      return `${BASE_URL}${LOGIN}`
    case 'forgetPassword':
      return `${BASE_URL}${FORGET_PASSWORD}`
    case 'resetPassword':
      return `${BASE_URL}${RESET_PASSWORD}/${id}`
    case 'addMember':
      return `${BASE_URL}${ADD_MEMBER}`
    case 'updateMember':
      return `${BASE_URL}${UPDATE_MEMBER}/${id}`
    case 'deleteMember':
      return `${BASE_URL}${DELETE_MEMBER}/${id}`
    case 'fetchMembers':
      return `${BASE_URL}${FETCH_MEMBERS}`
    case 'uploadImage':
      return `${BASE_URL}${UPLOAD_IMAGE}`
  }
}

const getHeaders = () => {
  return {
    'Content-Type': 'application/json'
  }
}

const useApi = id => {
  const [isLoading, setIsLoading] = useState(false)

  const updateCustomer = (payload, id) => {
    const config = {
      method: PATCH,
      url: generateUrl('customer', id),
      headers: getHeaders(),
      data: payload
    }

    axios(config)
      .then(response => {
        console.log(JSON.stringify(response.data))
      })
      .catch(error => {
        console.log(error)
      })
  }

  const login = payload => {
    setIsLoading(true)
    const config = {
      method: POST,
      url: generateUrl('login'),
      headers: getHeaders(),
      data: payload
    }
    return axios(config)
  }

  const forgetPassword = payload => {
    setIsLoading(true)
    const config = {
      method: POST,
      url: generateUrl('forgetPassword'),
      headers: getHeaders(),
      data: payload
    }

    return axios(config)
  }

  const resetPassword = payload => {
    setIsLoading(true)
    const { otp } = payload
    const config = {
      method: POST,
      url: generateUrl('resetPassword', otp),
      headers: getHeaders(),
      data: payload
    }

    return axios(config)
  }

  // ----> Settings <-----

  // Create member or Update member with same API
  const addMember = payload => {
    setIsLoading(true)
    const config = {
      method: POST,
      url: generateUrl('addMember'),
      headers: getHeaders(),
      data: payload
    }

    return axios(config)
  }

  // Update member
  const updateMember = (payload, id) => {
    setIsLoading(true)
    const config = {
      method: PUT,
      url: generateUrl('updateMember', id),
      headers: getHeaders(),
      data: payload
    }
    return axios(config)
  }

  // Delete member
  const deleteMember = id => {
    setIsLoading(true)
    const config = {
      method: DELETE,
      url: generateUrl('deleteMember', id),
      headers: getHeaders()
    }
    return axios(config)
  }

  // Fetch members
  const fetchMembers = () => {
    setIsLoading(true)
    const config = {
      method: GET,
      url: generateUrl('fetchMembers'),
      headers: getHeaders()
    }
    return axios(config)
  }

  // Upload image to s3
  const uploadImage = payload => {
    const formData = new FormData()
    formData.append('document', payload)
    setIsLoading(true)
    const config = {
      method: POST,
      url: generateUrl('uploadImage'),
      headers: getHeaders(),
      data: formData
    }
    return axios(config)
  }

  return {
    updateCustomer,
    login,
    forgetPassword,
    resetPassword,
    setIsLoading,
    addMember,
    fetchMembers,
    uploadImage,
    deleteMember,
    updateMember,
    isLoading
  }
}

export default useApi
