import CryptoJS from 'crypto-js'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { toast } from 'react-toastify'

/** Create a function to send an Expiry date again provided value
 *  By default this link will expire in 30 minutes, but this value
 *  can me extend
 *  @param { number } minutes
 **/
export const getExpireDate = (minutes = 30) => {
  const currentDate = new Date()
  return new Date(currentDate.setMinutes(currentDate.getMinutes() + minutes))
}

export const generateLink = (currentPath, callback) => {
  const exprDate = getExpireDate()
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(exprDate),
    'expDate'
  ).toString()
  const removeSlash = ciphertext.replaceAll('/', 'buga')
  const arr = currentPath.split('/')
  const baseURL = arr[2]
  const customerEmail = arr[3]
  const finalUrl =
    'https://www.quote.cffinsure.com' + '/' + removeSlash + '/' + customerEmail
  callback(finalUrl)
}

/** Get current Table name
 * @param { number } key
 * @return { String }
 */
const getTabName = key => {
  switch (key) {
    case 1:
      return 'business'
    case 2:
      return 'vehicles'
    case 3:
      return 'drivers'
    case 4:
      return 'document'
    default:
      return key
  }
}

/**
 * Generate Avatar background color
 */
export const generateAvatarColor = () => {
  return Math.floor(Math.random() * 16777215).toString(16)
}

/*
    * Display First Charectar on Avator.
      @param {string} name - from get first charector
    */
export const displayFirstChar = (name = '') => {
  return name?.charAt(0)
}

// Sweat Alert
export const Salert = (message, type, callback) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: `Yes, ${message}!`
  }).then(result => {
    if (result.isConfirmed) {
      callback()
      switch (type) {
        case 'logout':
          Swal.fire('LoggedOut!', 'Your have been logged Out.', 'success')
          break
        case 'delete':
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
          break
      }
    }
  })
}

// Toastify
export const notify = (message = '') => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark'
  })
}
